import React from 'react';
import GlobalContextProvider from './GlobalContextProvider';
import MapComponent from './components/MapComponent/MapComponent';
import GlobalStyle from './assets/GlobalStyle';
import { ThemeProvider } from 'styled-components';
import theme from './assets/theme';
import './assets/style.scss';
import Globe from './components/buttons/Globe';
import ArticleList from './components/ArticleList/ArticleList';
import OpenStreetMap from './components/copyRight/OpenStreetMap';

const App = () => {

  return (
    <GlobalContextProvider>
      <GlobalStyle />
      <ThemeProvider theme = { theme }>
      <MapComponent />
      <Globe />
      <ArticleList />
      <OpenStreetMap />
      </ThemeProvider>
    </GlobalContextProvider>
  );
}

export default App;
