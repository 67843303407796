const theme = {
    colors: {
        logo: '#0A294E',
        white: '#ffffff',
        main: '#676767',
        colorMain : '#85A55D',
        articleTitle: '#666666',
        grey: '#dddddd',
        lightGrey: '#F5F5F5',
        tree: '#f39000',
    },
    media: {
        small: 'only screen and (max-width: 767px)',
        medium: 'only screen and (max-width: 920px)',
        xl: 'only screen and (min-width: 768px)'
    }
}

export default theme;
