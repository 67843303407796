import React, { useContext } from 'react';
import { GlobalStateContext } from '../../GlobalContextProvider';
import { StyledWrapper } from './StyledArticleList';
import ArticleCard from '../ArticleCard/ArticleCard';
import Article from '../ArticleComponent/Article';
import ArticleListOpen from '../buttons/ArticleListOpen';

const ArticleList = () => {
    const state = useContext(GlobalStateContext);
    const features = state.allfeatures;
    const open = state.openArticleList;
    const openArticle = state.singleArticle;

    return (
        <>
            <StyledWrapper open = { open ? `flex` : `none` }>
                {   
                    openArticle ?
                    <Article /> :
                    features.map((feature, i) => {
                        return <ArticleCard key = { i } title = { feature.values_.title } opis = { feature.values_.content } point = { feature.values_.geometry.flatCoordinates } foto = { feature.values_.foto } link = { feature.values_.link } feature = { feature } wartosc = { feature.values_.wartosc} grafika = {feature.values_.grafika} /> })
                }
                {/* <BottomMenu />            */}
            </StyledWrapper> 
            <ArticleListOpen /> 
        </>
    )
}

export default ArticleList;
