import styled from 'styled-components';

export const StyledWrapper = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    background-color: ${ ({ theme }) => theme.colors.white };
    padding: 10px 0;
    align-items: center;
    border-bottom: 1px solid ${ ({theme}) => theme.colors.grey };

    &:hover {
        background-color: ${ ({ theme }) => theme.colors.lightGrey };
    }
`;

export const StyledLogo = styled.div`
    background-image: ${ ({ url }) => `url(https://mapa.miastaidrzewa.gridw.pl/WebServices/generic/Media.asmx/Download?thumbnail_size=small&uuid=${ url })`};
    background-size: contain;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center; 
`;


export const StyledTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    width: 70%;
`;

export const StyledTitle = styled.h3`
    height: 100%;
    display: flex;
    color: ${ ({ theme }) => theme.colors.tree };
    align-items: center;
`;

export const LgogWrapper = styled.div`
    width: 30%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
`;

export const StyledSubtitle = styled.p`
    color: ${({theme}) => theme.colors.main};
`;