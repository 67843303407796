import styled from 'styled-components';

export const StyledWrapper = styled.div`
    display: ${({ open }) => open }; 
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.white };
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    overflow-x: hidden;
    width: 30%;
    max-width:600px;

    @media ${({ theme }) => theme.media.medium } {
        width: 50%;
    }
    @media ${({ theme }) => theme.media.small } {
        width: 100%;
    }    
`;
