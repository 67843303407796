import React from 'react';

export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

const initialState = {
    point: [ 2106001, 6531000 ],
    zoom: 13,
    allfeatures: [],
    fetchFeatures: [],
    singleArticle: false,
    articleContent: {},
    openCategory: false,
    openArticleList: true,
    activeFeature: [],
    activeFeatureId: '0'
}

function reducer(state, action) {
    switch (action.type){
        case 'ZOOM_CHANGE':
            return {
                ...state,
                zoom: action.payload,
            }
            
        case 'CENTER_CHANGE':
            return {
                ...state,
                point: action.payload
            }
        
        case 'CHANGE_FEATURES':
            return {
                ...state,
                allfeatures: action.payload
            }
        
        case 'OPEN_ARTICLE':
            return {
                ...state,
                singleArticle: action.payload
            }
        
        case 'OPEN_CATEGORY':
            return {
                ...state,
                openCategory: state.openCategory ? false : true
            }
        
        case 'FILL_ARTICLE_CONTENT':
            return {
                ...state,
                articleContent : {
                    title: action.title,
                    content: action.content,
                    foto: action.foto,
                    link: action.link,
                    point: action.point,
                    wartosc: action.wartosc
                }
            }
        
        case 'CLOSE_ARTICLE':
            return {
                ...state,
                singleArticle: false
            }  
            
        case 'FETCH_FEATURES' :
            return {
                ...state, 
                fetchFeatures: action.payload
            }

        case 'OPEN_ARTICLELIST': 
            return {
                ...state,
                openArticleList: action.payload
            }

        case 'SET_ACTIVE_FEATURE': 
            return {
            ...state, 
            activeFeature: state.activeFeature[0] === action.payload ? [] : [action.payload]
         }
        
        case 'SET_ACTIVE_FEATURE_ID':
            return {
                ...state,
                activeFeatureId: state.activeFeatureId === action.payload ? '0' : action.payload
         }

        default: 
            throw new Error('Bad action type')
    }
};

const GlobalContextProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    return(
        <GlobalStateContext.Provider value = {state}>
            <GlobalDispatchContext.Provider value = {dispatch}>
                { children }
            </GlobalDispatchContext.Provider>
        </GlobalStateContext.Provider>
    )
};

export default GlobalContextProvider;
